* {
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: "Myriad Pro";
  src: url("./MyriadPro.ttf");
}
.header {
  background-image: url(../img/desktopView.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 33rem;
  display: flex;
}

.textContainer {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: fit-content;
  padding: 40px 50px;
  background-color: rgb(255, 255, 255, 0.7);
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transform: translate(6%, 5%);
  -webkit-transform: translate(6%, 5%);
  -moz-transform: translate(6%, 5%);
  -ms-transform: translate(6%, 5%);
  -o-transform: translate(6%, 5%);
}
.logo {
  width: 200px;
  height: auto;
  align-self: center;
}
.title {
  margin: 20px;
  color: #7a0726;
  font-size: 40px;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: "Bodoni Moda";
  font-weight: 700;
}
.span {
  color: black;
  font-size: 25px;
  margin-top: 10px;
  font-weight: 600;
  font-family: "Myriad Pro";
}
.text {
  margin: 20px;
  font-size: 20px;
  text-align: center;
}

/*----- Botones de contacto -----*/
.contactContainer {
  display: flex;
  flex-direction: column;
  width: 65%;
  align-items: flex-end;
  margin-right: 32px;
  justify-content: center;
}
.iconButton {
  background-color: #7a0726;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  margin: 15px;
  cursor: pointer;
}
.iconButton:hover {
  transform: scale(1.3);
  box-shadow: 0 10px 20px -5px rgba(255, 255, 255, 0.3);
  border: 1.5px solid #fff;
}
.image1 {
  width: 30px;
  transform: translate(11px, 15px);
}
.image2 {
  width: 15px;
  transform: translate(-6px, -2px);
}
.image3 {
  width: 35px;
  transform: translate(11px, 10px);
}
.image4 {
  width: 36px;
  transform: translate(10px, 10px);
}
.image5 {
  width: 36px;
  transform: translate(10px, 8px);
}

/*----- Carrusel -----*/
.bigRibbon {
  width: 100%;
  color: #7a0726;
  background-color: #7a0726;
  height: 10px;
  border: 0;
  margin-bottom: 5px;
}
.bigRibbon:not(:first-child) {
  margin-top: 5px;
}
.ribbon {
  width: 100%;
  color: #7a0726;
  background-color: #7a0726;
  height: 3px;
  border: 0;
  margin-bottom: 5px;
}
.ribbon:not(:first-child) {
  margin-top: 5px;
}
.slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 200px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 200px;
  position: absolute;
  width: 350px;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slide-track {
  animation: slider 50s linear infinite;
  display: flex;
  width: calc(250px * 14);
  -webkit-animation: slider 50s linear infinite;
}

.slide {
  height: 200px;
  width: 350px;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin: 0 5px;
}
/*----- Footer -----*/
footer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 25px 0;
}
.footerLogo {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footerLogo img {
  width: 130px;
  height: auto;
  align-self: center;
  margin-top: 10px;
}
.footerContact {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.footerContact a {
  text-decoration: none;
  display: flex;
  color: black;
  font-size: 18px;
  margin: 5px 0;
}
.footerContact a img {
  width: 15px;
}
.footerContact a p {
  margin-left: 10px;
  font-family: "Myriad Pro";
}
.footerLocation {
  width: 30%;
  display: flex;
  justify-content: flex-start;
}
.footerLocation a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  text-align: center;
  margin-top: 30px;
  font-family: "Myriad Pro";
}

/*----- animaciones -----*/
@keyframes slider {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-500px * 7));
    -webkit-transform: translateX(calc(-500px * 7));
    -moz-transform: translateX(calc(-500px * 7));
    -ms-transform: translateX(calc(-500px * 7));
    -o-transform: translateX(calc(-500px * 7));
  }
}

/*----- Responsive design -----*/
@media screen and (min-width: 1800px) {
  .header {
    height: 44rem;
  }
  .textContainer {
    transform: translate(3rem, 5rem);
    -webkit-transform: translate(3rem, 5rem);
    -moz-transform: translate(3rem, 5rem);
    -ms-transform: translate(3rem, 5rem);
    -o-transform: translate(3rem, 5rem);
    padding: 3.5rem 2rem;
}
  .title {
    font-size: 60px;
    margin: 25px 0;
    font-weight: 800;
  }
  .span {
    font-size: 35px;
  }
  .footerLocation a {
    font-size: 25px;
  }
  .footerContact p {
    font-size: 25px;
  }
  .footerContact a img {
    width: 25px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1291px) {
  .header {
    background-image: url(../img/desktopView.png);
  }
  .textContainer {
    width: 70%;
    transform: translate(2rem, 3rem);
    -webkit-transform: translate(2rem, 3rem);
    -moz-transform: translate(2rem, 3rem);
    -ms-transform: translate(2rem, 3rem);
    -o-transform: translate(2rem, 3rem);
}
  .contactContainer {
    width: 30%;
  }
}
@media screen and (min-width: 640px) and (max-width: 769px) {
  .header {
    background-image: url(../img/tabletView.png);
  }
  .textContainer {
    width: 70%;
  }
  .contactContainer {
    width: 20%;
  }
  footer {
    flex-direction: column-reverse;
  }
  .footerLogo {
    display: none;
  }
  .footerLocation {
    width: 100%;
  }
  .footerLocation a {
    padding: 20px 30px 40px;
    margin-top: 0;
  }
  .footerContact {
    width: 100%;
    margin: 20px 0;
    align-items: center;
  }
}
@media screen and (min-width: 380px) and (max-width: 639px) {
  .header {
    background-image: url(../img/mobileView.png);
    flex-direction: column;
    justify-content: center;
    height: unset;
  }
  .textContainer {
    padding: 40px 0;
    width: 95%;
    transform: translate(0, 5%);
    align-self: center;
    -webkit-transform: translate(0, 5%);
    -moz-transform: translate(0, 5%);
    -ms-transform: translate(0, 5%);
    -o-transform: translate(0, 5%);
  }
  .contactContainer {
    width: 100%;
    flex-direction: row;
    margin-right: 0;
    margin-top: 15px;
  }
  .iconButton {
    width: 45px;
    height: 45px;
  }

  .image1 {
    width: 23px;
    transform: translate(8px, 13px);
    -webkit-transform: translate(8px, 13px);
    -moz-transform: translate(8px, 13px);
    -ms-transform: translate(8px, 13px);
    -o-transform: translate(8px, 13px);
  }
  .image2 {
    width: 10px;
    transform: translate(-6px, -2px);
    -webkit-transform: translate(-6px, -2px);
    -moz-transform: translate(-6px, -2px);
    -ms-transform: translate(-6px, -2px);
    -o-transform: translate(-6px, -2px);
  }
  .image3 {
    width: 25px;
    transform: translate(9px, 10px);
    -webkit-transform: translate(9px, 10px);
    -moz-transform: translate(9px, 10px);
    -ms-transform: translate(9px, 10px);
    -o-transform: translate(9px, 10px);
  }
  .image4 {
    width: 26px;
    transform: translate(9px, 10px);
    -webkit-transform: translate(9px, 10px);
    -moz-transform: translate(9px, 10px);
    -ms-transform: translate(9px, 10px);
    -o-transform: translate(9px, 10px);
  }
  .image5 {
    width: 26px;
    transform: translate(9px, 8px);
    -webkit-transform: translate(9px, 8px);
    -moz-transform: translate(9px, 8px);
    -ms-transform: translate(9px, 8px);
    -o-transform: translate(9px, 8px);
  }
  footer {
    flex-direction: column-reverse;
  }
  .footerLogo {
    display: none;
  }
  .footerLocation {
    width: 100%;
  }
  .footerLocation a {
    padding: 20px 30px 40px;
    margin-top: 0;
  }
  .footerContact {
    width: 100%;
    margin: 20px 0;
    align-items: center;
  }
}
@media screen and (min-width: 280px) and (max-width: 379px) {
  .header {
    background-image: url(../img/mobileView.png);
    flex-direction: column;
    justify-content: center;
    height: unset;
  }
  .textContainer {
    padding: 40px 0;
    width: 95%;
    transform: translate(0, 5%);
    align-self: center;
    -webkit-transform: translate(0, 5%);
    -moz-transform: translate(0, 5%);
    -ms-transform: translate(0, 5%);
    -o-transform: translate(0, 5%);
  }
  .contactContainer {
    width: 100%;
    flex-direction: row;
    margin-right: 0;
    margin-top: 45px;
  }
  .iconButton {
    width: 45px;
    height: 45px;
    margin: 5px;
  }
  .image1 {
    width: 23px;
    transform: translate(8px, 13px);
    -webkit-transform: translate(8px, 13px);
    -moz-transform: translate(8px, 13px);
    -ms-transform: translate(8px, 13px);
    -o-transform: translate(8px, 13px);
  }
  .image2 {
    width: 10px;
    transform: translate(-6px, -2px);
    -webkit-transform: translate(-6px, -2px);
    -moz-transform: translate(-6px, -2px);
    -ms-transform: translate(-6px, -2px);
    -o-transform: translate(-6px, -2px);
  }
  .image3 {
    width: 25px;
    transform: translate(10px, 10px);
    -webkit-transform: translate(10px, 10px);
    -moz-transform: translate(10px, 10px);
    -ms-transform: translate(10px, 10px);
    -o-transform: translate(10px, 10px);
  }
  .image4 {
    width: 26px;
    transform: translate(9px, 10px);
    -webkit-transform: translate(9px, 10px);
    -moz-transform: translate(9px, 10px);
    -ms-transform: translate(9px, 10px);
    -o-transform: translate(9px, 10px);
  }
  .image5 {
    width: 26px;
    transform: translate(9px, 8px);
    -webkit-transform: translate(9px, 8px);
    -moz-transform: translate(9px, 8px);
    -ms-transform: translate(9px, 8px);
    -o-transform: translate(9px, 8px);
  }
  footer {
    flex-direction: column-reverse;
  }
  .footerLogo {
    display: none;
  }
  .footerLocation {
    width: 100%;
  }
  .footerLocation a {
    padding: 20px 30px 40px;
    margin-top: 0;
  }
  .footerContact {
    width: 100%;
    margin: 20px 0;
    align-items: center;
  }
}
